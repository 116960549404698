import {GetSimpleImage} from "../helpers/get-simple-image"

export const Clients = () => {
    return (
        [
            {
                link: "https://praktisgallery.art",
                image: GetSimpleImage("praktisgallery.jpg"),
                alt: "praktisgallery",
            },
            {
                link: "https://yaroslaffsoltan.com",
                image: GetSimpleImage("soltan.jpg"),
                alt: "yaroslaff soltan",
            },
            {
                link: "http://wysocki-fizjoterapia.pl",
                image: GetSimpleImage("wysocki.jpg"),
                alt: "wysocki fizjoterapia",
            },
            {
                link: "https://solar-technics.pl",
                image: GetSimpleImage("solar-technics.png"),
                alt: "solar-technics",
            },

        ]
    )
}
