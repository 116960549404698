module.exports = {
    webDevelopment: {
        title: "Web <br/> development",
        tooltip: "Z owoców wspólnego doświadczenia wyciskamy wyłącznie to, co najlepsze. Projektujemy <strong>responsywne</strong> i w pełni spersonalizowane strony internetowe. W tym celu łączymy intuicyjny system zarządzania treścią, który umożliwia samodzielną zmianę zawartości z ReactJS, wykorzystywanym do tworzenia nieszablonowych interfejsów graficznych.",
    },
    graphics: {
        title: "Projekty <br/> graficzne",
        tooltip: "Tworząc identyfikację wizualną my sprzeciwiamy się schematom a świadomość Twojej marki <strong>rośnie</strong>. Oferujemy kompleksowe i profesjonalne projekty graficzne, które cechują się koncepcją dopracowaną z najwyższą precyzją.",
    },
    seo: {
        title: "SEO",
        tooltip: "Pozycjonujemy strony internetowe i sklepy na poziomie lokalnym i globalnym.\nDbamy o to, by Twoja strona była <strong>wysoko</strong> w organicznych wynikach wyszukiwania. Pozycjonowanie wykonujemy razem z działaniami SEO.",
    },
    maintenance: {
        title: "Utrzymanie <br/> i&nbsp;rozwój <br/> aplikacji",
        tooltip: "Oferujemy wsparcie i rozwój aplikacji biznesowej, czyniąc ją <strong>atrakcyjną</strong> dla użytkowników. W pracy korzystamy z niezawodnych rozwiązań, które gwarantują nowoczesny przekaz. Z ogromną skutecznością potrafimy zgłębić każdy język programowania, na którym funkcjonuje istniejąca strona.",
    },
}
