import React from "react"
import ReactHtmlParser from "react-html-parser"
import { Tooltip, OverlayTrigger } from "react-bootstrap"
import "./tooltip.scss"

export default function TooltipText({ text, placement, tooltipText, classname }) {
    return (
        <OverlayTrigger className={`tooltip`}
                        placement={placement}
                        overlay={
                            <Tooltip>
                                {ReactHtmlParser(tooltipText)}
                            </Tooltip>
                        }>
            <p className={classname}>
                {ReactHtmlParser(text)}
            </p>
        </OverlayTrigger>
    )
}
